<div class="page-container">
    <div class="page-container">
        <div class="page-container">
            <div fxLayout="row" class="full-width">
                <div class="login-card mat-elevation-z6">

                    <div class="content">
                        <div class="buy-button">
                            <p>Success</p>
                        </div>
                        <div class="margin">
                            <p class="login-text">Dit køb er gennemført</p>
                            <p>Din billet er sendt til din email, men du kan også downloade vores app og se din
                                billet direkte i appen</p>
                        </div>
                       <div class="app-store">
                            <img  src="../../../../../assets/images/google-play-badge_dk.png" alt="" (click)="open('https://play.google.com/store/apps/details?id=com.happenings.happenings&hl=en')">
                            <img  src="../../../../../assets/images/App-store-badge.png" alt="" (click)="open('https://apps.apple.com/dk/app/id1522929615')">

                       </div>    



                    </div>
                </div>
            </div>
        </div>
    </div>
</div>