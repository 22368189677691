import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MatCardModule} from '@angular/material/card';
import {MatFormFieldModule} from '@angular/material/form-field';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ToastrModule } from 'ngx-toastr';
import { HttpClientModule } from '@angular/common/http';
import { HeaderComponent } from './shared/header/header.component';
import { FooterComponent } from './shared/footer/footer.component';
import { MatIconModule } from '@angular/material/icon';
import {MatToolbarModule} from '@angular/material/toolbar';
import { MatNativeDateModule } from '@angular/material/core';
import { MatButtonModule } from '@angular/material/button';
import {MatBadgeModule} from '@angular/material/badge';
import { AccessDeniedComponent } from './shared/access-denied/access-denied.component';
import { NotFoundComponent } from './shared/not-found/not-found.component';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatDividerModule} from '@angular/material/divider';
import { AddOrganizerDialogComponent } from './shared/dialogs/add-organizer-dialog/add-organizer-dialog.component';
import { AddOrganizationDialogComponent } from './shared/dialogs/add-organization-dialog/add-organization-dialog.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSelectModule } from '@angular/material/select';
import { FlexLayoutModule } from '@angular/flex-layout';
import { EditOrganisationDialogComponent } from './shared/dialogs/edit-organisation-dialog/edit-organisation-dialog.component';
import { EditOrganizerDialogComponent } from './shared/dialogs/edit-organizer-dialog/edit-organizer-dialog.component';
import { PaymentErrorComponent } from './modules/ticket-app/components/payment-error/payment-error.component';
import { PaymentSuccessComponent } from './modules/ticket-app/components/payment-success/payment-success.component';
import { GetGuestDetailsComponent } from './shared/dialogs/get-guest-details/get-guest-details.component';
import {enableProdMode} from '@angular/core';

enableProdMode();

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    AccessDeniedComponent,
    NotFoundComponent,
    AddOrganizerDialogComponent,
    AddOrganizationDialogComponent,
    EditOrganisationDialogComponent,
    EditOrganizerDialogComponent,
    PaymentErrorComponent,
    PaymentSuccessComponent,
    GetGuestDetailsComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatCardModule,
    MatFormFieldModule,
    NgxSpinnerModule,
    ToastrModule.forRoot(),
    HttpClientModule,
    MatIconModule,
    MatToolbarModule,
    MatNativeDateModule,
    MatButtonModule,
    MatBadgeModule,
    MatSidenavModule,
    MatDividerModule,
    FormsModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    MatDialogModule,
    MatSelectModule,
    FlexLayoutModule

    
  ],
  entryComponents: [
    EditOrganisationDialogComponent,
    AddOrganizationDialogComponent,
    AddOrganizerDialogComponent,
    EditOrganizerDialogComponent,
    GetGuestDetailsComponent
 ],
  providers: [],
  bootstrap: [AppComponent],
  exports: [
  ]
})
export class AppModule { }
