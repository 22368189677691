import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TicketAppService } from 'src/app/services/ticket-app/ticket-app.service';
import { NordeaPayService } from 'src/app/services/nordea-pay/nordea-pay.service';
import { TicketUpdate } from 'src/app/models/nordea-payment';

@Component({
  selector: 'app-payment-success',
  templateUrl: './payment-success.component.html',
  styleUrls: ['./payment-success.component.scss']
})
export class PaymentSuccessComponent implements OnInit {


  userTicketId: string;

  constructor(private route: ActivatedRoute, private paymentSvc: NordeaPayService) { }




  ngOnInit(): void {
    this.getRoutingParams();
  }


  ngAfterViewInit(): void {
    this.generateTicket();
  }

  open(url: string) {
    window.open(url, '_blank');
  }

  /**
  * Gets the routing paramaters from the success url
  * Used to update the user tickets on succesfull payment
  */
  getRoutingParams() {

    this.route.params.subscribe(params => {
      console.log(params.userDetails);
      console.log(params.ticketDetails);
      this.userTicketId = params.ticketDetails;

    });
  }

  /**
 * Set ticket status to PAID in the database
 */

  generateTicket() {
    const ticket: TicketUpdate = {
      userTicketId: this.userTicketId,
      paymentStatus: 'PAID',
      paymentReference: this.userTicketId
    };

    this.paymentSvc.updateTicket(ticket);

  }

}
