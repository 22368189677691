import { Injectable } from '@angular/core';
import { Organizer, PostOrganizer, PostOrganization, Organization, Organizations } from 'src/app/models/organization';
import { HttpHelperService } from '../http-helper/http-helper.service';
import { LoginService } from '../login/login.service';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { PostHappening } from 'src/app/models/happenings';
import { environment } from 'src/environments/environment';
import { MatTableDataSource } from '@angular/material/table';
import { async } from '@angular/core/testing';
import { BaseUser } from 'src/app/models/baseuser';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  /**
   * API Url
   */
  url = 'https://partnertestapi.azurewebsites.net/api/';
  /**
   * Smart Array of all Organizations
   */
  allOrganizations: MatTableDataSource<Organization>;

  /**
   * Smart Array of all Organizations
   */
  allOrganizationsArray: Organization[];

  /**
   * Smart Array of all Organizers
   */
  allOrganizers: MatTableDataSource<Organizer>;

  // tslint:disable-next-line: max-line-length
  constructor(private httpHelper: HttpHelperService, private loginSvc: LoginService, private toastr: ToastrService, private spinner: NgxSpinnerService, private http: HttpClient) { }

  /**
   * POST new Organizer to DB
   * @param happening A PostHappening Object
   */
  postOrganizer(organizationId: string, organizer: PostOrganizer){
    const request = 'organizers/register';
    this.spinner.show();

    this.httpHelper.post<PostOrganizer>(environment.API_URL + request, organizer)
      .subscribe(
        (async data => {
          this.spinner.hide();
          this.toastr.success(organizer.name + ' er oprettet som Arrangør', 'Succes');
        }),
        error => {
          console.log(JSON.stringify(error));
          this.toastr.error(error.error.errors, 'Ups! Vi kunne ikke oprette Arrangøren');
          this.spinner.hide();
        }
      );
  }

  /**
   * POST new Organization to DB
   * @param organization A POST organization object - its different from Organization
   */
  postOrganization(organization: PostOrganization){
    const request = 'organizations/register';
    this.spinner.show();
    

    console.log(JSON.stringify(organization));

    this.httpHelper.post<PostOrganizer>(environment.API_URL + request, organization)
      .subscribe(
        (async data => {
          this.toastr.success(organization.name + ' er oprettet som Organisation', 'Succes');
          this.spinner.hide();
        }),
        error => {
          console.log(error);
          this.toastr.error(error.error.errors, 'Ups! Vi kunne ikke oprette Organisationen');
          this.spinner.hide();
        }
      );
  }

  /**
   * GET all organizations
   * Prepares a MatTableDataSource (Used in MatTable as the datasource) of all organizations.
   * in the html set [DataSource]="USerService?.allOrganizations"
   */
  getOrganizations() {
    const request = 'organizations';
    this.spinner.show();

    this.httpHelper.get<any>(environment.API_URL + request)
      .subscribe(
        (data => {
          console.log(data);
          //this.toastr.success('Alle organisationer hentet korrekt', 'Succes');
          this.allOrganizations = new MatTableDataSource(data.organizations);
          this.allOrganizationsArray = data.organizations;
          this.spinner.hide();
        }),
        error => {
          console.log(error);
          this.toastr.error(error.error.errors, 'Ups! Der skete en fejl');
          this.spinner.hide();
        }
      );
  }

  /**
   * GET all organizers
   * Prepares a MatTableDataSource (Used in MatTable as the datasource) of all organizers.
   * in the html set [DataSource]="USerService?.allOrganizers"
   */
  getOrganizers() {
    const request = 'organizers';
    //this.spinner.show();

    this.httpHelper.get<any>(environment.API_URL + request)
      .subscribe(
        (data => {
          console.log(data);
          this.toastr.success('Alle arrangører hentet korrekt', 'Succes');
          this.allOrganizers = new MatTableDataSource(data.organizers);
          this.spinner.hide();
        }),
        error => {
          console.log(error);
          this.toastr.error(error.error.errors, 'Ups! Der skete en fejl');
          this.spinner.hide();
        }
      );
  }

  /**
   * Update existing Organization in DB
   * @param organization An organization object
   */
  updateOrganization(organization: Organization){
    const request = 'organizations/' + organization.organizationId;
    this.spinner.show();
    console.log('print organizationId');
    console.log(organization.organizationId);
    console.log('print organisation object');
    console.log(organization);
    console.log('print request url');
    console.log(request);

    //console.log(JSON.stringify(organization));

    this.httpHelper.put<Organization>(environment.API_URL + request, organization)
    .subscribe(
      (async data => {
        this.toastr.success(organization.name + 'er opdateret', 'Succes');
        this.spinner.hide();
      }),
      error => {
        console.log(error);
        this.toastr.error(error.status + ' ' + error.statusText, 'Ups! Vi kunne ikke updatere Organizationen');
        this.spinner.hide();
      }
    );
  }

  /**
   * Update existing Organizer in DB
   * @param organization An organization object
   */
  updateOrganizer(organizer: Organizer){
    const request = 'organizers/' + organizer.organizerId;
    this.spinner.show();

    console.log(JSON.stringify(organizer));

    this.httpHelper.put<Organizer>(environment.API_URL + request, organizer)
    .subscribe(
      (async data => {
        this.toastr.success(organizer.name + 'er opdateret', 'Succes');
        this.spinner.hide();
      }),
      error => {
        console.log(error);
        this.toastr.error(error.status + ' ' + error.statusText, 'Ups! Vi kunne ikke opdatere Organizationen');
        this.spinner.hide();
      }
    );
  }

  /**
   * Posts new app/tickets user to the DB
   * @param user baseuser
   */
  postUser(user: BaseUser){
    const request = 'users/register';
    this.spinner.show();


    this.http.post<BaseUser>(environment.TICKET_API_URL + request, user)
    .subscribe(
      (async data => {
        this.spinner.hide();
      }),
      error => {
        console.log(error);
        this.toastr.error(error.error.error, 'Ups! Vi kunne ikke oprette din bruger, prøv venligst igen eller kontakt vores support');
        this.spinner.hide();
      }
    );
  }

}
