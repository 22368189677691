import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Organizer } from 'src/app/models/organizer';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UserService } from 'src/app/services/user/user.service';
import { LoginService } from 'src/app/services/login/login.service';

@Component({
  selector: 'app-edit-organizer-dialog',
  templateUrl: './edit-organizer-dialog.component.html',
  styleUrls: ['./edit-organizer-dialog.component.scss']
})
export class EditOrganizerDialogComponent implements OnInit {

  /**
   * FormGroup used for formcontrol in the edit organizer form
   */
  organizerForm: FormGroup;

  /**
   * Organizer injected from the parent
   * This object will be initialized with the selected row from the parent table
   */
  organizer: Organizer;

  /**
   * name of parent
   */
  parentName: string;


  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<EditOrganizerDialogComponent>,
    private fb: FormBuilder,
    public userSvc: UserService,
    public loginSvc: LoginService
  ) { }

  ngOnInit(): void {
    this.initializeOrganization();
    this.createForm();
  }

  /**
   * Initializes the organization object with the injected organization object from the parent
   */
  initializeOrganization() {
    this.organizer = this.data;
    console.log('printing Organizer object inside editOrganizerDialog');
    console.log(this.organizer);
  }

   /**
   * Creates the form for formcontrol in the edit organization dialog
   * Used to make sure the form fields are validated
   */
  createForm() {
    this.organizerForm = this.fb.group({
      name: ['', Validators.required],
      phone: ['', Validators.required],
      email: ['', Validators.required],
      organizationId: ['', Validators.required]
    });
  }

  /**
   * Updates the selected organizer
   * If the form isn't valid then the organizerForm.markAllAsTouched() will be called, this will give errors on all fields that aren't properly filled
   */
  async updateOrganizer() {
    console.log('inside updateOrganizer()');
    console.log(this.organizer);
    if (this.organizerForm.valid) {
      console.log('inside updateOrganizer() IF');
      const newOrganizer: Organizer = {
        organizerId: this.organizer.organizerId,
        name: this.organizer?.name,
        phone: this.organizer?.phone,
        email: this.organizer?.email,
        organization: this.organizer.organization
      };
      console.log(newOrganizer);
      await this.userSvc.updateOrganizer(newOrganizer);
      this.close();

    } else {
      console.log('inside updateOrganizer() ELSE');
      this.organizerForm.markAllAsTouched();
    }

  }

  deleteOrganizer(){
    console.log('deleteOrganizer()');
  }

  /**
   * Closes the dialog
   * Called from updateOrganizer()
   */
  close() {
    this.dialogRef.close();
  }

}
