import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { LoginService } from '../login/login.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService {

  constructor(private loginSvc: LoginService, private router: Router) { }
  
  /**
   * Guard method, protects the platform from being acccessed without an authenticated user
   * Used in app-routing.module
   */
  canActivate(): Observable<boolean> | boolean {

    if (this.loginSvc.isUserAuthenticated) { return true; }

    this.router.navigate(['login']);

    return false;

  }  
}
