// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  API_URL: 'https://partnertestapi.azurewebsites.net/api/',
  TICKET_API_URL: 'https://happeningstestapi.azurewebsites.net/api/',
  BEARERTOKEN: '',

  // Nordea Pay
  MerchantID: 759,
  APIPasswird: '3DmPT5tfVB0V',
  Secret: '$2a$12$CsGHEePq21nVY1aNlESSTO',
  AgreementIdNordeaConnect: 'NC138048',
  NordeaUserName: 'Happenings',
  NordeaPassword: 'CloudPro4Ever',
  NordeaTOKEN: '',
  NordeaAPI_URL: 'https://happeningsnordeaconnectapi.azurewebsites.net/',

  // Version
  version: '1.1.0'

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
