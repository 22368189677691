<div class="title mat-elevation-z6">
    <!--<div class="title">-->
    <div class="header">
        <p class="title-text">Edit Organisation</p>
    </div>
    <!--<div class="button-row" fxLayout="row">
        <button class="button close-btn" (click)="close()">
            <mat-icon>cancel</mat-icon>
        </button>
    </div>-->
</div>

<div fxLayout="row" class="content">
    <form class="form" [formGroup]="organizationForm" (ngSubmit)="updateOrganization()">
        <!-- Name field -->
        <mat-form-field class="form-field">
            <mat-label>Name</mat-label>
            <input matInput type="text" class="form-control" formControlName="name"
                [(ngModel)]="organization.name" maxlength="250"/>
            <mat-error *ngIf="organizationForm.controls.name.touched && !organizationForm.controls.name.valid">
                Organization name is <strong>required</strong>
            </mat-error>
        </mat-form-field>
        <div class="text-length">
            <p>{{250 - organization.name?.length}}</p>
        </div>
        <!-- CVR field -->
        <mat-form-field class="form-field">
            <mat-label>CVR</mat-label>
            <input matInput type="text" class="form-control" formControlName="cvr"
                [(ngModel)]="organization.cvr" maxlength="250"/>
            <mat-error *ngIf="organizationForm.controls.cvr.touched && !organizationForm.controls.cvr.valid">
                CVR  <strong>påkrævet</strong>
            </mat-error>
        </mat-form-field>
        <div class="text-length">
            <p>{{1000 - organization.cvr?.length}}</p>
        </div>
        <!-- Street field -->
        <mat-form-field class="form-field">
            <mat-label>Street</mat-label>
            <input matInput type="text" class="form-control" formControlName="street"
                [(ngModel)]="organization.street" maxlength="250"/>
            <mat-error
                *ngIf="organizationForm.controls.street.touched && !organizationForm.controls.street.valid">
                Gadenavn<strong>påkrævet</strong>
            </mat-error>
        </mat-form-field>
        <div class="text-length">
            <p>{{250 - organization.street?.length}}</p>
        </div>
        <!-- street field end-->
        <!-- Postal Code field -->
        <mat-form-field class="form-field">
            <mat-label>Postal code</mat-label>
            <input matInput type="number" class="form-control" formControlName="postalCode"
                [(ngModel)]="organization.postalCode" maxlength="250"/>
            <mat-error
                *ngIf="organizationForm.controls.postalCode.touched && !organizationForm.controls.postalCode.valid">
                Postnummer <strong>påkrævet</strong>
            </mat-error>
        </mat-form-field>
        <div class="text-length">
            <!-- <p>{{250 - organization.postalCode?.length}}</p> -->
        </div>
        <!-- Postal Code field end-->
        <!-- City field -->
        <mat-form-field class="form-field">
            <mat-label>City</mat-label>
            <input matInput type="text" class="form-control" formControlName="city"
                [(ngModel)]="organization.city" maxlength="250"/>
            <mat-error
                *ngIf="organizationForm.controls.city.touched && !organizationForm.controls.city.valid">
                By <strong>påkrævet</strong>
            </mat-error>
        </mat-form-field>
        <div class="text-length">
            <p>{{250 - organization.city?.length}}</p>
        </div>
        <!-- City field end-->
         <!-- Fee Percent field -->
         <mat-form-field class="form-field">
            <mat-label>Fee percentage</mat-label>
            <input matInput type="number" class="form-control" formControlName="feePercent"
                [(ngModel)]="organization.feePercent" maxlength="250"/>
            <mat-error
                *ngIf="organizationForm.controls.feePercent.touched && !organizationForm.controls.feePercent.valid">
                Procentdel <strong>påkrævet</strong>
            </mat-error>
        </mat-form-field>
        <div class="text-length">
           <!-- <p>{{250 - organization.feePercent?.length}}</p> -->
        </div>
        <!-- Fee Percent field end-->
        <!-- Fee Min field -->
        <mat-form-field class="form-field">
            <mat-label>Fee min</mat-label>
            <input matInput type="number" class="form-control" formControlName="feeMin"
                [(ngModel)]="organization.feeMin" maxlength="250"/>
            <mat-error
                *ngIf="organizationForm.controls.feeMin.touched && !organizationForm.controls.feeMin.valid">
                Minimum Procentdel <strong>påkrævet</strong>
            </mat-error>
        </mat-form-field>
        <div class="text-length">
            <!-- <p>{{250 - organization.feeMin?.length}}</p> -->
        </div>
        <!-- Fee Min field end-->
        <!-- Institution Id field -->
        <mat-form-field class="form-field">
            <mat-label>Institution Id</mat-label>
            <input matInput type="text" class="form-control" formControlName="institutionId"
                [(ngModel)]="organization.institutionId" maxlength="250"/>
            <mat-error
                *ngIf="organizationForm.controls.institutionId.touched && !organizationForm.controls.institutionId.valid">
                Institutions Id <strong>påkrævet</strong>
            </mat-error>
        </mat-form-field>
        <div class="text-length">
            <p>{{250 - organization.institutionId?.length}}</p>
        </div>
        <!-- Institution Id field end-->
        <button class="add-button" mat-button (click)="deleteOrganization()">Delete</button>
        <button type="submit" class="add-button" mat-button>
            Opdater
        </button>
        
    </form>
</div>
<!--</div>-->
