import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuardService } from './services/auth-guard/auth-guard.service';
import { AccessDeniedComponent } from './shared/access-denied/access-denied.component';
import { AuthGuardAdminService } from './services/auth-guard-admin/auth-guard-admin.service';
import { NotFoundComponent } from './shared/not-found/not-found.component';
import { PaymentErrorComponent } from './modules/ticket-app/components/payment-error/payment-error.component';
import { PaymentSuccessComponent } from './modules/ticket-app/components/payment-success/payment-success.component';


const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  //{ path: '**', redirectTo: '404'},
  { path: '403', component: AccessDeniedComponent},
  { path: '404', component: NotFoundComponent},
  {
    path: 'login', loadChildren: () => import('src/app/modules/login/login.module').then(m =>
      m.LoginModule)
  },
  {
    path: 'home', loadChildren: () => import('src/app/modules/home/home.module').then(m =>
      m.HomeModule), canActivate: [AuthGuardService]
  },
  {
    path: 'administration', loadChildren: () => import('src/app/modules/administration/administration.module').then(m =>
      m.AdministrationModule), canActivate: [AuthGuardAdminService]
  },
  {
    path: 'users', loadChildren: () => import('src/app/modules/users/users.module').then(m =>
      m.UsersModule), canActivate: [AuthGuardService]
  },
  {
    path: 'happenings', loadChildren: () => import('src/app/modules/happenings/happenings.module').then(m =>
      m.HappeningsModule), canActivate: [AuthGuardService]
  },
  {
    path: 'my-profile', loadChildren: () => import('src/app/modules/my-profile/my-profile.module').then(m =>
      m.MyProfileModule), canActivate: [AuthGuardService]
  },
  {
    path: 'tickets/:happeningid', loadChildren: () => import('src/app/modules/ticket-app/ticket-app.module').then(m =>
      m.TicketAppModule)
  },

  // Used for payment errors
  { path: 'error/:ticketDetails', component: PaymentErrorComponent},

  // Used for payment success
  { path: 'success/:ticketDetails', component: PaymentSuccessComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }