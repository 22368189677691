<div class="title mat-elevation-z6">
    <div class="header">
        <p class="title-text">Rediger Arrangør</p>
    </div>
    <!-- <div class="button-row" fxLayout="row">
        <button class="button close-btn" (click)="close()">
            <mat-icon>cancel</mat-icon>
        </button>
    </div> -->
</div>

<div fxLayout="row" class="content">
    <p>Her kan du opdaterer arrangør profilen</p>
    <form class="form" [formGroup]="organizerForm" (ngSubmit)="updateOrganizer()">
        <!-- Name field -->
        <mat-form-field class="form-field">
            <mat-label>Navn</mat-label>
            <input matInput type="text" class="form-control" formControlName="name" [(ngModel)]="organizer.name"
                maxlength="250" />
            <mat-error *ngIf="organizerForm.controls.name.touched && !organizerForm.controls.name.valid">
                Skal udfyldes
            </mat-error>
        </mat-form-field>
        <div class="text-length">
            <p>{{250 - organizer.name?.length}}</p>
        </div>

        <!-- Phone field -->
        <mat-form-field class="form-field">
            <mat-label>Mobil</mat-label>
            <input matInput type="number" class="form-control" formControlName="phone" [(ngModel)]="organizer.phone"
                maxlength="12" />
            <mat-error class="error-text"
                *ngIf="organizerForm.controls.phone.touched && !organizerForm.controls.phone.valid">
                Skal udfyldes
            </mat-error>
        </mat-form-field>

        <!-- Email field -->
        <mat-form-field class="form-field">
            <mat-label>Email</mat-label>
            <input matInput type="email" class="form-control" formControlName="email" [(ngModel)]="organizer.email"
                maxlength="250" />
            <mat-error class="error-text"
                *ngIf="organizerForm.controls.email.touched && !organizerForm.controls.email.valid">
                Skal udfyldes
            </mat-error>
        </mat-form-field>
        <div class="text-length">
            <p>{{250 - organizer.email?.length}}</p>
        </div>

        <!-- Organization field -->
        <mat-form-field *ngIf="loginSvc.isUserAdmin" class="form-field">
            <mat-label>Moderorganisation</mat-label>
            <mat-select [(ngModel)]="organizer.organization.name" name="organizations" formControlName="organizationId">
                <mat-option *ngFor="let o of userSvc?.allOrganizationsArray" [value]="organizer.organization.name">
                    {{o.name}}
                </mat-option>
            </mat-select>
            <mat-error class="error-text"
                *ngIf="organizerForm.controls.organizationId.touched && !organizerForm.controls.organizationId.valid">
                Vælg venligst en organisation
            </mat-error>
        </mat-form-field>

        <button class="add-button" mat-button (click)="deleteOrganizer()">Slet</button>
        <button type="submit" class="add-button" mat-button>Opdater</button>

    </form>
</div>