import { Injectable } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { LoginCredentials } from 'src/app/models/login-credentials';
import * as jwt_decode from 'jwt-decode';
import { User } from 'src/app/models/user';
import { Router } from '@angular/router';
import { HttpHelperService } from '../http-helper/http-helper.service';
import { Organization, Organizer } from 'src/app/models/organization';
import { TicketUser } from 'src/app/models/ticket-user';
import { BaseUser } from 'src/app/models/baseuser';

@Injectable({
  providedIn: 'root'
})
export class LoginService {


  /**
   * Public bearer token so that it can be used by all http requests in th app
   * Can be accessed with BearerTokenService.BEARERTOKEN
   */
  public BEARERTOKEN: string;

  /**
   * API request
   */
  private request = 'auth/login';

  /**
   * Saves the user that logs in (Comes from a decoded jwt token)
   */
  loggedInUser: User;

  /**
   * If the user logs in to the Ticket Site, the user info is stored here
   */
  loggedInTicketUser: TicketUser;

  /**
   * boolean that is called to see if the user is authorized
   * Used in auth-guard and header/footer components
   */
  isUserAuthenticated = false;


  /**
   * booleans that are called to determine the user type.
   * Used in auth-guard and header/footer components
   */
  isUserAdmin = false;
  isUserOrganization = false;
  isUserOrganizer = false;
  isUserUser = false;

  /**
   * The mother organization saved during login
   */
  organization: Organization;

  /**
   * If the logged in user is an Organizer, this is the variable to hold the organizer
   */
  organizer: Organizer;

  // tslint:disable-next-line: max-line-length
  constructor(private http: HttpClient, private toastr: ToastrService, private spinner: NgxSpinnerService, private router: Router, private httpHelper: HttpHelperService) { }

  /**
   * Partner site login service
   * Sets the user status to logged in
   */
  login(credentials: LoginCredentials) {
    this.spinner.show();
    this.http.post<any>(environment.API_URL + this.request, credentials)
      .subscribe(
        (async data => {
          // this.toastr.success('Bruger logget ind', 'Succes');
          this.BEARERTOKEN = data.token;
          environment.BEARERTOKEN =  data.token;
          console.log(data.token);
          const decoded =  jwt_decode(data.token);
          this.loggedInUser = decoded;
          // Organization Login
          if (this.loggedInUser.role === 'Organization') {
            await this.getOrganization();
            this.isUserAuthenticated = true;
            this.isUserOrganization = true;
            console.log('isUserUser:' + this.isUserUser);
            console.log('isUserOrganization:' + this.isUserOrganization);
            console.log('isUserOrganizer:' + this.isUserOrganizer);
            console.log('isUserAdmin:' + this.isUserAdmin);
            this.checkUserRole();
            this.router.navigate(['home']);
            this.spinner.hide();
          }
          // Organizer Login
          else if (this.loggedInUser.role === 'Organizer') {
            await this.getOrganizer();
            this.isUserAuthenticated = true;
            this.isUserOrganizer = true;
            console.log('isUserUser:' + this.isUserUser);
            console.log('isUserOrganization:' + this.isUserOrganization);
            console.log('isUserOrganizer:' + this.isUserOrganizer);
            console.log('isUserAdmin:' + this.isUserAdmin);
            this.checkUserRole();
            this.router.navigate(['home']);
            this.spinner.hide();
          }
          // User Login
          else if (this.loggedInUser.role === 'User') {
            // Set to false, so the header wont show
            this.isUserUser = true;
            await this.getTicketUserInformation();
          }
          // Admin Login
          else if (this.loggedInUser.role === 'Admin') {
            this.isUserAuthenticated = true;
            this.isUserAdmin = true;
            console.log('isUserUser:' + this.isUserUser);
            console.log('isUserOrganization:' + this.isUserOrganization);
            console.log('isUserOrganizer:' + this.isUserOrganizer);
            console.log('isUserAdmin:' + this.isUserAdmin);
            this.checkUserRole();
            this.router.navigate(['home']);
            this.spinner.hide();
          }
        }),
        error => {
          console.log(error);
          this.toastr.error(error.error.errors, 'Ups! Der skete en fejl');
          this.spinner.hide();
        }
      );
  }

  /**
   * Checks the user role and sets the corresponding boolean to true/false
   * Call the role booleans to give/restrict access based on role
   */
  checkUserRole() {
    if (this.loggedInUser.role === 'Admin') {
      this.isUserAdmin = true;
    }
  }

  /**
   * Not done yet
   */
  resetPassword() {
    // TODO Add reset password functionality
    this.toastr.success('Vi har desværre ikke implementeret denne feature endnu, kontakt venligst support på support@happenings.dk', 'Information');
  }

  /**
   * Logs the user out and clears all saved tokens and settings
   */
  logout() {
    if (confirm('Er du sikker på du vil logge ud?')) {
      this.loggedInUser = null;
      this.isUserAuthenticated = false;
      this.isUserAdmin = false;
      this.BEARERTOKEN = '';
      this.router.navigate(['']);
    }
  }

  /**
   * Once the user is authenticated, the organization behind it has to be retrieved
   * This method retrieves the organization once the user has logged in, and before navigation to [home]
   */
  getOrganization() {
    const request = 'organizations/organization';

    this.httpHelper.get<any>(environment.API_URL + request)
      .subscribe(
        (data => {
          console.log(data);
          this.organization = data;
        }),
        error => {
          console.log(error);
          this.toastr.error(error.error.errors, 'Ups! Der skete en fejl');
          this.spinner.hide();
        }
      );

  }

  /**
   * If the user role is ORganizer, this is where the organizer is retrieved
   */
  getOrganizer() {
    const request = 'organizers/organizer';

    this.httpHelper.get<any>(environment.API_URL + request)
      .subscribe(
        (data => {
          console.log(data);
          this.organizer = data;
        }),
        error => {
          console.log(error);
          this.toastr.error(error.error.errors, 'Ups! Der skete en fejl');
          this.spinner.hide();
        }
      );
  }

  /**
   * Returns the organizerId based on the role of the loggedInUser role
   */
  getOrganizerId(): string {
    if (this.loggedInUser.role === 'Organization') {
      return this.organization.organizers[0].organizerId;
    } else if (this.loggedInUser.role === 'Organizer') {
      return this.organizer.organizerId;
    }
    return null;
  }

  /**
   * Ticket App User
   * Gets the user info for the user that logs in through the Ticket App
   */
  getTicketUserInformation() {
    const request = 'users';

    this.httpHelper.get<BaseUser>(environment.TICKET_API_URL + request)
      .subscribe(
        (data => {
          this.loggedInTicketUser = data;
          this.isUserAuthenticated = false;
          console.log(this.loggedInTicketUser);
          if (this.loggedInTicketUser.uniUserInfo !== null){
            this.router.navigate(['tickets/happening/buy-ticket']);
            this.spinner.hide();
          } else {
            this.spinner.hide();
            this.router.navigate(['tickets/unilogin/happening/unilogin/']);
            this.toastr.success('For at købe billetter skal du tilknytte dit unilogin');
          }

          this.spinner.hide();
        }),
        error => {
          console.log(error);
          this.toastr.error(error.error.errors, 'Ups! Der skete en fejl');
          this.spinner.hide();
        }
      );
  }

}
