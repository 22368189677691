import { Component, OnInit } from '@angular/core';
import { LoginService } from 'src/app/services/login/login.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  
  /**
   * Menu array
   */
  public MENUITEMS = [
    {title: 'Hjem', link: 'home'},
    {title: 'Happenings', link: 'happenings'},
    //{title: 'Brugere', link: 'users'},
  ];

  constructor(public loginSvc: LoginService) { }

  ngOnInit(): void {
  }

  logout() {
    this.loginSvc.logout();
  }

  /**
   * Optional
   * Menu items for the header nav bar.
   * Each object has a title and a link, the link is used for the navigation method
   */
  setMenu() {
    console.log('print logged in user:');
    console.log(this.loginSvc.loggedInUser.role);
    if (this.loginSvc.loggedInUser.role === 'Organization') {
      this.MENUITEMS = [
        {title: 'Hjem', link: 'home'},
        {title: 'Happenings', link: 'happenings'},
        {title: 'Brugere', link: 'users'}
      ];
    } else if (this.loginSvc.loggedInUser.role === 'Organizer') {
      this.MENUITEMS = [
        {title: 'Hjem', link: 'home'},
        {title: 'Happenings', link: 'happenings'}
      ];
    } else if (this.loginSvc.loggedInUser.role === 'Admin') {
      this.MENUITEMS = [
        {title: 'Hjem', link: 'home'},
        {title: 'Happenings', link: 'happenings'},
        {title: 'Administration', link: 'administration'}
      ];
    }
  }


}
