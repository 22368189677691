<div class="title mat-elevation-z6">
    <div class="header">
        <p class="title-text">{{happeningGuest.user?.firstName}} {{happeningGuest.user?.lastName}}</p>
    </div>
    <!-- <div class="button-row" fxLayout="row">
            <button class="button close-btn" (click)="close()">
                <mat-icon>cancel</mat-icon>
            </button>
        </div> -->
</div>

<div fxLayout="row" class="content">
    <div fxLayout="row">
        <img mat-card-image class="img" src="{{happeningGuest.user?.imageUrl}}" alt="Billede af gæsten">
        <div fxFlex="20%">
            <p>Fornavn:</p>
            <p>Efternavn:</p>
            <p>Køn:</p>
            <p>Fødselsdato:</p>
            <p>Vej:</p>
            <p>Postnummer:</p>
            <p>By:</p>
    
        </div>

        <div>
            <p>{{happeningGuest.user?.firstName}}</p>
            <p>{{happeningGuest.user?.lastName}}</p>
            <p>{{happeningGuest.user?.gender}} </p>
            <p>{{happeningGuest.user?.birthDate | date}} </p>
            <p>{{happeningGuest.user?.street}} </p>
            <p>{{happeningGuest.user?.postalCode}} </p>
            <p>{{happeningGuest.user?.city}} </p>
    
        </div>
    </div>
</div>