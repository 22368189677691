import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LoginService } from '../login/login.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardAdminService {

  constructor(private loginSvc: LoginService, private router: Router) { }

  canActivate(): Observable<boolean> | boolean {
    if (this.loginSvc.isUserAdmin) { return true; }

    this.router.navigate(['403']);

    return false;
  }

}
