import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Organization } from 'src/app/models/organization';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogService } from 'src/app/services/dialog/dialog.service';
import { UserService } from 'src/app/services/user/user.service';

@Component({
  selector: 'app-edit-organisation-dialog',
  templateUrl: './edit-organisation-dialog.component.html',
  styleUrls: ['./edit-organisation-dialog.component.scss']
})
export class EditOrganisationDialogComponent implements OnInit {

  /**
   * FormGroup used for formcontrol in the edit organization form
   */
  organizationForm: FormGroup;

  /**
   * Organization injected from the parent
   * This object will be initialized with the selected row from the parent table
   */
  organization: Organization;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<EditOrganisationDialogComponent>,
    private fb: FormBuilder,
    private userSvc: UserService
  ) {

   }

  ngOnInit(): void {
    this.initializeOrganization();
    this.createForm();
    console.log('the organization object');
    console.log(this.organization);
  }

  /**
   * Initializes the organization object with the injected organization object from the parent
   */
  initializeOrganization() {
    this.organization = this.data;
  }

   /**
   * Creates the form for formcontrol in the edit organization dialog
   * Used to make sure the form fields are validated
   */
  createForm() {
    this.organizationForm = this.fb.group({
      name: ['', Validators.required],
      cvr: ['', Validators.required],
      street: ['', Validators.required],
      postalCode: ['', Validators.required],
      city: ['', Validators.required],
      feePercent: ['', Validators.required],
      feeMin: ['', Validators.required],
      institutionId: ['', Validators.required]
    });
  }

  /**
   * Updates the selected organization
   * If the form isn't valid then the organizationForm.markAllAsTouched() will be called, this will give errors on all fields that aren't properly filled
   */
  async updateOrganization() {
    console.log('inside updateOrganization()');
    console.log(this.organization);
    if (this.organizationForm.valid) {
      console.log('inside updateOrganization() IF');
      const newOrganization: Organization = {
        organizationId: this.organization.organizationId,
        name: this.organization.name,
        cvr: this.organization.cvr,
        street: this.organization.street,
        postalCode: this.organization.postalCode,
        city: this.organization.city,
        feePercent: this.organization.feePercent,
        feeMin: this.organization.feeMin,
        institutionId: this.organization.institutionId
      };
      console.log(newOrganization);
      await this.userSvc.updateOrganization(newOrganization);
      this.close();

    } else {
      console.log('inside updateOrganization() ELSE');
      this.organizationForm.markAllAsTouched();
    }

  }

  deleteOrganization(){
    console.log('deleteOrganization()');
  }

  /**
   * Closes the dialog
   * Called from updateOrganization()
   */
  close() {
    this.dialogRef.close();
  }



}
