import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { NgxSpinnerService } from 'ngx-spinner';
import { NordeaCredentials } from 'src/app/models/login-credentials';
import { NordeaPayment, ReserveTicket, TicketUpdate } from 'src/app/models/nordea-payment';

@Injectable({
  providedIn: 'root'
})
export class NordeaPayService {

  /**
   * Headers used in to make Nordea Connect API requests
   */
  headers: HttpHeaders;

  /**
   * Credentials to authorize the session to nordea connect and recieve an access token
   */
  apiCredentials: NordeaCredentials;

  /**
   * UserTicketId returned from reserveTicket
   * Used as customerref in the payment object
   */
  userTicketId: string;

  constructor(private http: HttpClient, private spinner: NgxSpinnerService) { }

  /**
   * Http options for nordea pay requests
   */
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Credentials': 'true',
      credentials: 'same-origin'
    }),
    withCredentials: true
  };

  /**
   * GET nordea connect token
   */
  getNordeaToken() {
    this.apiCredentials = {
      userName: environment.NordeaUserName,
      password: environment.NordeaPassword
    };
    const request = 'Access';
    this.spinner.show();

    // tslint:disable-next-line: max-line-length
    this.http.post<any>(environment.NordeaAPI_URL + request, this.apiCredentials, this.httpOptions)
      .subscribe(
        (data => {
          console.log(data);
          environment.NordeaTOKEN = data.token;
          this.httpOptions.headers = this.httpOptions.headers.set('Authorization', 'Bearer ' + data.token);
          console.log('http headers: ' + JSON.stringify(this.httpOptions.headers));
          this.spinner.hide();
        }),
        error => {
          console.log(error);
          this.spinner.hide();
        }
      );
  }

  /**
   * Creates the payment object and navigates to the secure payment portal
   * @param nordeaPayment Payment object
   */
  makePayment(nordeaPayment: NordeaPayment) {
    this.spinner.show();
    const request = 'Nordea';
    console.log(JSON.stringify(nordeaPayment))
    this.http.post<any>(environment.NordeaAPI_URL + request, nordeaPayment, this.httpOptions)
      .subscribe(
        (data => {
          console.log(data);
          this.spinner.hide();
          window.open(data.href);

        }),
        error => {
          console.log(error);
          this.spinner.hide();
        }
      );
  }

  /**
   * Used to reserve a ticket in the database for the user.
   * Called when makePayment is called - if it fails, and the ticket cant be reserved then it wont navigate to the payment window.
   * @param ticketCredentials ReserveTicket object
   */

  reserveTicket(ticketCredentials: ReserveTicket) {
    this.spinner.show();
    const request = 'tickets/reserve';
    this.http.post<any>(environment.TICKET_API_URL + request, ticketCredentials, this.httpOptions)
      .subscribe(
        (async data => {
          this.userTicketId = data.userTicketId;
          this.spinner.hide();
        }),
        error => {
          console.log(error);
          this.spinner.hide();
        }
      );
  }
  /**
   * Updates the ticket based on the payment response
   */
  updateTicket(ticket: TicketUpdate) {
    this.spinner.show();
    const request = 'tickets/userticket/status';
    this.http.put<any>(environment.TICKET_API_URL + request, ticket)
      .subscribe(
        (data => {
          console.log(data);
          this.spinner.hide();
        }),
        error => {
          console.log(error);


        });

  }

}
