import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogService } from 'src/app/services/dialog/dialog.service';
import { HappeningGuest } from 'src/app/models/happenings';

@Component({
  selector: 'app-get-guest-details',
  templateUrl: './get-guest-details.component.html',
  styleUrls: ['./get-guest-details.component.scss']
})
export class GetGuestDetailsComponent implements OnInit {

  /**
   * variable to hold the injected user selected happeningGuest object.
   */
  happeningGuest: HappeningGuest;

  constructor(
              @Inject(MAT_DIALOG_DATA) public data: any,
              private dialogRef: MatDialogRef<GetGuestDetailsComponent>,
            ) { }

  ngOnInit(): void {
    this.happeningGuest = this.data;
    console.log('happening guest object:');
    console.log(this.happeningGuest);
    this.happeningGuest.user.imageUrl
  }

  /**
   * Closes the dialog
   * Called from updateOrganizer()
   */
  close() {
    this.dialogRef.close();
  }

}
