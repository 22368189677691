import { Component, OnInit, Inject } from '@angular/core';
import { UserService } from 'src/app/services/user/user.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogService } from 'src/app/services/dialog/dialog.service';
import { environment } from 'src/environments/environment';
import { Organizer, PostOrganizer, Organization } from 'src/app/models/organization';

@Component({
  selector: 'app-add-organizer-dialog',
  templateUrl: './add-organizer-dialog.component.html',
  styleUrls: ['./add-organizer-dialog.component.scss']
})
export class AddOrganizerDialogComponent implements OnInit {

  /**
   * Two way binding property for the new Organizer
   */
  oName: string;

  /**
   * Two way binding property for the new Organizer
   */
  oPhone: string;

  /**
   * Two way binding property for the new Organizer
   */
  oEmail: string;

  /**
   * Two way binding property for the new Organizer
   */
  oPassword: string;

  /**
   * Two way binding property for the new Organizer
   */
  oOrganizationId: string;

  /**
   * FormGroup used for formcontrol in the addOrganizer form
   */
  organizerForm: FormGroup;


  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<AddOrganizerDialogComponent>,
    private fb: FormBuilder,
    public userSvc: UserService,
    public dialogSvc: DialogService) { }

  ngOnInit(): void {
    this.createForm();
  }

  /**
   * Creates the formcontrol in the add organizer dialog
   * Used to make sure the form fields are validated
   */
  createForm() {
    this.organizerForm = this.fb.group({
      name: ['', Validators.required],
      phone: ['', Validators.required],
      email: ['', Validators.required],
      organizationId: ['', Validators.required],
      password: ['', Validators.required]
    });
  }

  /**
   * Adds the new organizer to the DB
   * First we create a new object based on the two-way binding properties we have declared
   * If the form isn't valid then the organizerForm.markAllAsTouched() will be called, this will give errors on all fields that aren't properly filled
   */
  async addOrganizer() {
    if (this.organizerForm.valid) {
      const newOrganizer: PostOrganizer = {
        name: this.oName,
        phone: this.oPhone.toString(),
        email: this.oEmail,
        organizationId: this.oOrganizationId,
        password: this.oPassword
      };
      await this.userSvc.postOrganizer(newOrganizer.organizationId, newOrganizer);
      this.close();
    } else {
      this.organizerForm.markAllAsTouched();
    }
  }

  /**
   * Closes the dialog box
   */
  close() {
    this.dialogRef.close();
  }

}
