<div class="gandalf">
    <div class="fireball"></div>
    <div class="skirt"></div>
    <div class="sleeves"></div>
    <div class="shoulders">
        <div class="hand left"></div>
        <div class="hand right"></div>
    </div>
    <div class="head">
        <div class="hair"></div>
        <div class="beard"></div>
    </div>
</div>
<div class="message">
    <h1>403 - You Shall Not Pass</h1>
    <p>Du har ikke adgang til denne side<br />Kontakt os <a class="contact-link"
            href="mailto:support@happenings.dk?subject=Happenings partner&body=Send os venligst dine kontaktoplysninger, så bliver du kontaktet indenfor 24 timer af en af vores medarbejdere">her</a>
        hvis du mener du burde have adgang til denne del</p>
        <mat-icon class="home-icon" (click)="navigateToHome()">home</mat-icon>
</div>

