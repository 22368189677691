<div class="title mat-elevation-z6">
    <div class="header">
        <p class="title-text">Tilføj ny Arrangør</p>
    </div>
    <!-- <div class="button-row" fxLayout="row">
        <button class="button close-btn" (click)="close()">
            <mat-icon>cancel</mat-icon>
        </button>
    </div> -->
</div>

<div fxLayout="row" class="content">
    <p>Her kan du tilføje en ny arrangør til en organisation</p>
    <form class="form" [formGroup]="organizerForm" (ngSubmit)="addOrganizer()">
        <!-- Name field -->
        <mat-form-field class="form-field">
            <mat-label>Navn</mat-label>
            <input matInput type="text" class="form-control" formControlName="name" [(ngModel)]="oName" maxlength="250" />
            <mat-error *ngIf="organizerForm.controls.name.touched && !organizerForm.controls.name.valid">
                Skal udfyldes
            </mat-error>
        </mat-form-field>
        <div class="text-length">
            <p>{{250 - oName?.length}}</p>
        </div>

        <!-- Phone field -->
        <mat-form-field class="form-field">
            <mat-label>Mobil</mat-label>
            <input matInput type="number" class="form-control" formControlName="phone" [(ngModel)]="oPhone" maxlength="12" />
            <mat-error class="error-text" *ngIf="organizerForm.controls.phone.touched && !organizerForm.controls.phone.valid">
                Skal udfyldes
            </mat-error>
        </mat-form-field>

        <!-- Email field -->
        <mat-form-field class="form-field">
            <mat-label>Email</mat-label>
            <input matInput type="email" class="form-control" formControlName="email" [(ngModel)]="oEmail" maxlength="250" />
            <mat-error class="error-text" *ngIf="organizerForm.controls.email.touched && !organizerForm.controls.email.valid">
                Skal udfyldes
            </mat-error>
        </mat-form-field>
        <div class="text-length">
            <p>{{250 - oEmail?.length}}</p>
        </div>

        <!-- Password field -->
        <mat-form-field class="form-field">
            <mat-label>Password</mat-label>
            <input matInput type="email" class="form-control" formControlName="password" [(ngModel)]="oPassword" maxlength="250" />
            <mat-error class="error-text" *ngIf="organizerForm.controls.password.touched && !organizerForm.controls.password.valid">
                Skal udfyldes
            </mat-error>
        </mat-form-field>
        <div class="text-length">
            <p>{{250 - oPassword?.length}}</p>
        </div>

        <!-- Organization field -->
        <mat-form-field class="form-field">
            <mat-label>Vælg en moderorganisation</mat-label>
            <mat-select [(ngModel)]="oOrganizationId" name="organizations" formControlName="organizationId">
              <mat-option *ngFor="let o of userSvc?.allOrganizationsArray" [value]="o.organizationId">
                {{o.name}}
              </mat-option>
            </mat-select>
            <mat-error class="error-text" *ngIf="organizerForm.controls.organizationId.touched && !organizerForm.controls.organizationId.valid">
                Vælg venligst en organisation
            </mat-error>
          </mat-form-field>

        
        <button type="submit" class="add-button" mat-button>Tilføj Arrangør</button>
    </form>
</div>
