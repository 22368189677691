<div class="title mat-elevation-z6">
    <div class="header">
        <p class="title-text">Tilføj ny Organisation</p>
    </div>
</div>

<div fxLayout="row" class="content">
    <p>Her kan du tilføje en ny Organisation</p>
    <form class="form" [formGroup]="organizationForm" (ngSubmit)="checkPassword()">
        <!-- Name field -->
        <mat-form-field class="form-field">
            <mat-label>Navn</mat-label>
            <input matInput type="text" class="form-control" formControlName="name" [(ngModel)]="oName"
                maxlength="250" />
            <mat-error *ngIf="organizationForm.controls.name.touched && !organizationForm.controls.name.valid">
                Skal udfyldes
            </mat-error>
            
        </mat-form-field>
        <div class="text-length">
            <p>{{250 - oName?.length}}</p>
        </div>

        <!-- CVR field -->
        <mat-form-field class="form-field">
            <mat-label>CVR</mat-label>
            <input matInput type="number" class="form-control" formControlName="cvr" [(ngModel)]="oCvr" maxlength="8" />
            <mat-error *ngIf="organizationForm.controls.cvr.touched && !organizationForm.controls.cvr.valid">
                Skal udfyldes
            </mat-error>
        </mat-form-field>

        <!-- Phone field -->
        <mat-form-field class="form-field">
            <mat-label>Mobil</mat-label>
            <input matInput type="number" class="form-control" formControlName="phone" [(ngModel)]="oPhone"
                maxlength="12" />
            <mat-error class="error-text"
                *ngIf="organizationForm.controls.phone.touched && !organizationForm.controls.phone.valid">
                Skal udfyldes
            </mat-error>
        </mat-form-field>

        <!-- Email field -->
        <mat-form-field class="form-field">
            <mat-label>Email</mat-label>
            <input matInput type="email" class="form-control" formControlName="email" [(ngModel)]="oEmail"
                maxlength="250" />
            <mat-error class="error-text"
                *ngIf="organizationForm.controls.email.touched && !organizationForm.controls.email.valid">
                Skal udfyldes
            </mat-error>
        </mat-form-field>
        <div class="text-length">
            <p>{{250 - oEmail?.length}}</p>
        </div>

        <!-- Street field -->
        <mat-form-field class="form-field">
            <mat-label>Adresse</mat-label>
            <input matInput type="text" class="form-control" formControlName="street" [(ngModel)]="oStreet"
                maxlength="250" />
            <mat-error *ngIf="organizationForm.controls.street.touched && !organizationForm.controls.street.valid">
                Skal udfyldes
            </mat-error>
        </mat-form-field>
        <div class="text-length">
            <p>{{250 - oStreet?.length}}</p>
        </div>

        <!-- postalCode field -->
        <mat-form-field class="form-field">
            <mat-label>Postnummer</mat-label>
            <input matInput type="number" class="form-control" formControlName="postalCode" [(ngModel)]="oPostalCode"
                maxlength="4" />
            <mat-error
                *ngIf="organizationForm.controls.postalCode.touched && !organizationForm.controls.postalCode.valid">
                Skal udfyldes
            </mat-error>
        </mat-form-field>

        <!-- city field -->
        <mat-form-field class="form-field">
            <mat-label>By</mat-label>
            <input matInput type="text" class="form-control" formControlName="city" [(ngModel)]="oCity"
                maxlength="250" />
            <mat-error *ngIf="organizationForm.controls.city.touched && !organizationForm.controls.city.valid">
                Skal udfyldes
            </mat-error>
        </mat-form-field>
        <div class="text-length">
            <p>{{250 - oCity?.length}}</p>
        </div>

        <!-- feePercent field -->
        <mat-form-field class="form-field">
            <mat-label>Gebyr i %</mat-label>
            <span matSuffix>% &nbsp;</span>
            <input matInput type="number" class="form-control" formControlName="feePercent" [(ngModel)]="oFeePercent"
                maxlength="250" />
            <mat-error *ngIf="organizationForm.controls.feePercent.touched && !organizationForm.controls.feePercent.valid">
                Skal udfyldes
            </mat-error>
        </mat-form-field>

        <!-- minFee field -->
        <mat-form-field class="form-field">
            <mat-label>Min gebyr i kr</mat-label>
            <span matSuffix>kr &nbsp;</span>
            <input matInput type="number" class="form-control" formControlName="feeMin" [(ngModel)]="oFeeMin"
                maxlength="250" />
            <mat-error *ngIf="organizationForm.controls.feeMin.touched && !organizationForm.controls.feeMin.valid">
                Skal udfyldes
            </mat-error>
        </mat-form-field>

        <!-- Password field -->
        <mat-form-field class="form-field">
            <mat-label>Password</mat-label>
            <input matInput type="password" class="form-control" formControlName="password" [(ngModel)]="oPassword"
                maxlength="250" />
            <mat-error class="error-text"
                *ngIf="!organizationForm.controls.password.valid">
                Password matcher ikke
            </mat-error>
        </mat-form-field>

        <!-- Verify Password field -->
        <mat-form-field class="form-field">
            <mat-label>Gentag Password</mat-label>
            <input matInput type="password" class="form-control" formControlName="passwordVerified" [(ngModel)]="oPasswordVerified"
                maxlength="250" />
            <mat-error class="error-text"
                *ngIf="!organizationForm.controls.passwordVerified.valid">
                Password matcher ikke
            </mat-error>
        </mat-form-field>




        <button type="submit" class="add-button" mat-button>Tilføj Organisation</button>
    </form>
</div>