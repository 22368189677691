<div class="page-container">
    <div class="page-container">
        <div fxLayout="row" class="full-width">
            <div class="login-card mat-elevation-z6">

                <div class="content">
                    <div class="buy-button">
                        <p>Der skete en fejl</p>
                    </div>
                    <div class="margin">
                        <p class="login-text">Vi kunne desværre ikke gennemføre dit køb!</p>
                        <p class="location">Der skete en fejl og vi kunne ikke gennemføre din betaling - prøv venligst igen eller kontakt vores support</p>
                    </div>
                    



                </div>
            </div>
        </div>
    </div>
</div>