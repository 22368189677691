<mat-toolbar *ngIf="this.loginSvc.isUserAuthenticated" color="primary" class="navbar ">
    <mat-toolbar-row class="header mat-elevation-z6">
        <!-- Header logo -->
        <span><img class="header-image" src="../../../assets/icons/ms-icon-310x310.png" [routerLink]="['home']"
                alt=""></span>
        <!-- Header menu items -->
        <ng-container *ngFor="let m of MENUITEMS">
            <span class="nav-item" routerLink="{{m.link}}" routerLinkActive="active-link">{{m.title}}</span>
        </ng-container>
        <span *ngIf="loginSvc.isUserOrganization " class="nav-item" routerLink="users" routerLinkActive="active-link">Brugere</span>
        <span *ngIf="loginSvc.isUserAdmin" class="nav-item" routerLink="administration" routerLinkActive="active-link">Administration</span>
        <span class="example-spacer"></span>
        <!-- <mat-icon *ngIf="loginSvc.isUserAdmin" [routerLink]="['admin']" class="user-icon" aria-hidden="false"
            aria-label="My profile">
            vpn_lock</mat-icon> &nbsp; &nbsp; -->
            <p *ngIf="loginSvc.isUserOrganization || loginSvc.isUserAdmin || loginSvc.isUserOrganizer" class="add-btn-text" [routerLink]="['happenings/new-happening']">Ny Happening</p>
        <mat-icon [routerLink]="['my-profile']" class="user-icon" aria-hidden="false" aria-label="My profile">
            account_circle</mat-icon>
        <mat-icon (click)="logout()" class="logout-icon" aria-hidden="false" aria-label="My profile">
            exit_to_app</mat-icon>

    </mat-toolbar-row>
</mat-toolbar>

