import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';

@Injectable({
  providedIn: 'root'
})
export class DialogService {

  dialogRef: any;

 
  constructor(public dialog: MatDialog, private spinner: NgxSpinnerService) { }

  /**
   * Opens a dialog box
   * @param parent the parent to be refreshed on close
   * @param component the dialog component
   * @param heightInput the height of the dialog box
   * @param maxHeightInput max height of the dialog box
   * @param widthInput width of the dialog box
   * @param maxWidthInput max width of the dialog box
   * @param minWidthInput min width of the dialog box
   * @param dataInput the data to send to the dialog box
   */
  addDialog(parent, component, heightInput = 'auto',
            maxHeightInput = '500px', widthInput = 'auto', maxWidthInput = '75%',
            minWidthInput = '50%', dataInput = null) {
    // Opens dialog box with data
    if (dataInput !== null) {
      this.dialogRef = this.dialog.open(component, {
        height: heightInput,
        maxHeight: maxHeightInput,
        width: widthInput,
        maxWidth: maxWidthInput,
        minWidth: minWidthInput,
        data: dataInput
      });
    }
    // Opens dialog box without data
    else {
      this.dialogRef = this.dialog.open(component, {
        height: heightInput,
        maxHeight: maxHeightInput,
        width: widthInput,
        maxWidth: maxWidthInput,
        minWidth: minWidthInput
      });
    }

    this.dialogRef.afterClosed().subscribe(result => {
      // this.spinner.show();
      setTimeout(() => {
        parent.ngOnInit();
      }, 2000);
    });
  }
}
