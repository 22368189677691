import { Injectable } from '@angular/core';
import { LoginService } from '../login/login.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HttpHelperService {

  constructor(private http: HttpClient) { }

  getToken() {
    return environment.BEARERTOKEN;
  }

  get<t>(url, csv?): any {
    let httpOptions;
    if (csv !== undefined && csv !== null) {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'my-auth-token',
          'Access-Control-Allow-Credentials': 'true',
          credentials: 'same-origin'
        }),
        withCredentials: true,
        responseType: 'text/csv'
      };
    } else {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'my-auth-token',
          'Access-Control-Allow-Credentials': 'true',
          credentials: 'same-origin'
        }),
        withCredentials: true
      };
    }

    httpOptions.headers = httpOptions.headers.set('Authorization', 'Bearer ' + this.getToken());
    httpOptions.withCredentials = true;
    return this.http.get<t>(url, httpOptions);
  }

  post<t>(url, body) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'my-auth-token',
        'Access-Control-Allow-Credentials': 'true',
        credentials: 'same-origin'
      }),
      withCredentials: true
    };

    httpOptions.headers = httpOptions.headers.set('Authorization', 'Bearer ' + this.getToken());
    httpOptions.withCredentials = true;
    return this.http.post<t>(url, body, httpOptions);
  }

  put<t>(url, body) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'my-auth-token',
        'Access-Control-Allow-Credentials': 'true',
        credentials: 'same-origin'
      }),
      withCredentials: true
    };

    httpOptions.headers = httpOptions.headers.set('Authorization', 'Bearer ' + this.getToken());
    httpOptions.withCredentials = true;
    return this.http.put<t>(url, body, httpOptions);
  }

  delete<t>(url) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'my-auth-token',
        'Access-Control-Allow-Credentials': 'true',
        credentials: 'same-origin'
      }),
      withCredentials: true
    };

    httpOptions.headers = httpOptions.headers.set('Authorization', 'Bearer ' + this.getToken());
    httpOptions.withCredentials = true;
    return this.http.delete<t>(url, httpOptions);
  }
}
