import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Organization, PostOrganization } from 'src/app/models/organization';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UserService } from 'src/app/services/user/user.service';
import { DialogService } from 'src/app/services/dialog/dialog.service';

@Component({
  selector: 'app-add-organization-dialog',
  templateUrl: './add-organization-dialog.component.html',
  styleUrls: ['./add-organization-dialog.component.scss']
})
export class AddOrganizationDialogComponent implements OnInit {

  /**
   * Two way binding property for the new Organization
   */
  oName: string;

  /**
   * Two way binding property for the new Organization
   */
  oCvr: string;

  /**
   * Two way binding property for the new Organization
   */
  oPhone: string;

  /**
   * Two way binding property for the new Organization
   */
  oEmail: string;

  /**
   * Two way binding property for the new Organization
   */
  oStreet: string;

  /**
   * Two way binding property for the new Organization
   */
  oPostalCode: number;

  /**
   * Two way binding property for the new Organization
   */
  oCity: string;

  /**
   * Two way binding property for the new Organization
   */
  oFeePercent: number;

  /**
   * Two way binding property for the new Organization
   */
  oFeeMin: number;

  /**
   * Two way binding property for the new Organization
   */
  oInstitutionId: string;

  /**
   * Two way binding property for the new Organization
   */
  oPassword: string;

  /**
   * Property for verifying password
   */
  oPasswordVerified: string;

  /**
   * FormGroup used for formcontrol in the addOrganization form
   */
  organizationForm: FormGroup;



  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<AddOrganizationDialogComponent>,
    private fb: FormBuilder,
    private userSvc: UserService,
    public dialogSvc: DialogService) { }

  ngOnInit(): void {
    this.createForm();
  }

  /**
   * Creates the formcontrol in the add Organization dialog
   * Used to make sure the form fields are validated
   */
  createForm() {
    this.organizationForm = this.fb.group({
      name: ['', Validators.required],
      cvr: ['', Validators.required],
      phone: ['', Validators.required],
      email: ['', Validators.required],
      street: ['', Validators.required],
      postalCode: ['', Validators.required],
      city: ['', Validators.required],
      feePercent: ['', Validators.required],
      feeMin: ['', Validators.required],
      password: ['', Validators.required],
      passwordVerified: ['', Validators.required]
    });
  }

  /**
   * Adds the new Organization to the DB
   * First we create a new object based on the two-way binding properties we have declared
   * If the form isn't valid then the OrganizationForm.markAllAsTouched() will be called, this will give errors on all fields that aren't properly filled
   */
  async addOrganization() {
    if (this.organizationForm.valid) {
      const newOrganization: PostOrganization = {
        name: this.oName,
        cvr: this.oCvr.toString(),
        phone: this.oPhone.toString(),
        email: this.oEmail,
        street: this.oStreet,
        postalCode: this.oPostalCode,
        city: this.oCity,
        feePercent: this.oFeePercent,
        feeMin: this.oFeeMin,
        password: this.oPassword
      };
      await this.userSvc.postOrganization(newOrganization);
      this.close();
    } else {
      this.organizationForm.markAllAsTouched();
    }
  }
  close() {
    this.dialogRef.close();
  }

  /**
   * Verifies that passwords match
   * If passwords match =>
   */
  checkPassword() {
    if (this.oPassword === this.oPasswordVerified) {
      this.addOrganization();
    } else {
      this.organizationForm.controls.password.setErrors({ 'invalid': true });
      this.organizationForm.controls.passwordVerified.setErrors({'invalid': true});
    }
  }
}

